<template>
  <div class="text-center white">
    <v-menu
        :close-on-content-click="false"
        v-model="showMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            ref="selected"
            dense
            :outlined="!isFlat"
            v-bind="attrs"
            v-on="on"
            v-model="selected"
            :label="hintText"
            :rules="isRequired ? [getRules.required] : []"
            validate-on-blur
            :flat="isFlat"
            hide-details
            readonly
            clearable
            @click:clear="clearResponse"
        ></v-text-field>
      </template>
      <div class="white pl-2 pr-2 pt-2">
        <v-text-field
            dense
            outlined
            v-model="search"
            label="Search"
            single-line
            hide-details
            clearable
            append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <h6 v-if="Object.keys(options).length === 0" class="text-center white w-100  py-4 text-body-1">No Record found</h6>
      <v-treeview
          v-else
          hoverable
          dense
          activatable
          selection-type="independent"
          :search="search"
          :filter="filter"
          style="max-height: 200px;min-height: 50px;"
          :items="options"
          open-on-click
          :active="[activeRecord]"
          class="overflow-y-auto"
          item-key="id"
      >
        <template v-slot:label="{ item, open }">
          <div @click=nodeSelected(item)>
            <div class="d-flex justify-start align-center v-tree__pointer">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title class="text-body-2 text-truncate mw-600" v-on="on" v-bind="attrs"><strong>{{ item.name }}</strong> <span  v-if="displayPendingCount === true">({{item.venue_count}} Inactive Venue)</span>
                    </v-list-item-title>
                  </template>
                  <span>{{item.name}}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>
      </v-treeview>
    </v-menu>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SelectBox",
  props: {
    title: {
      type: String,
      required: false,
      default: "Created as",
    },
    isFlat: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    displayPendingCount: {
      type: Boolean,
      required: false,
    },
    selectedItemId: {
      type: Number,
      default: null
    },
    selectedItemName: {
      type: String,
      default: null
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    openMenu: {
      type: Boolean,
      required: true,
      default: false,
    },
    hintText:
        {
          type: String,
          required: false,
          default: "Created As",
        },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  computed: {
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
    ...mapGetters(['getRules'])
  },
  watch: {
    default() {
      this.selected = this.default.title;
      this.activeRecord = this.default.id;
    },
    selected(v){
      if (!!v){
        this.$refs.selected.resetValidation();
      }
    },
  },
  data() {
    return {
      showMenu: false,
      search: null,
      activeNode: [],
      selected: "",
      open: this.openMenu,
      childSelected: "",
      collapsedNodes: [],
      activeRecord: "",
    };
  },
  mounted() {
    this.$emit("input", this.selected);
    if (this.default != null) {
      this.selected = this.default.title;
      this.activeRecord = this.default.id;
    }
    this.nodeSelected({id: this.selectedItemId, name: this.selectedItemName});
  },
  methods: {
    clearResponse() {
      this.$emit('input', null);
    },
    nodeSelected(node) {
      this.showMenu = false;
      this.selected = node.name;
      this.search = null;
      this.open = false;
      this.$emit('input', node.id);
    },
  },
};
</script>
<style scoped lang="scss">
.mw-600 { max-width: 600px; }
::v-deep .v-treeview { background-color: white; }
::v-deep .v-text-field__details { margin-bottom: 0px !important; }
</style>