<template>
  <div>

    <v-chip
        class="text-uppercase d-flex flex-column cursor-pointer"
        :class="vendor.status | statusColor"
        label
        small
        v-if="vendor.status === 'draft' || vendor.status === 'inactive'"
    >
      {{vendor.status | status}}
    </v-chip>

    <v-speed-dial
        v-else
        v-model="fab[`fab-${vendor.id}-${vendor.type}`]"
        :direction="direction"
        :open-on-hover="(vendor.status === 'active' || vendor.status === 'hide')"
        :transition="transition"
    >
      <template v-slot:activator>
        <v-chip class="text-uppercase d-flex flex-column cursor-pointer" :class="vendor.status | statusColor" label small>
          {{vendor.status | status}}
        </v-chip>
      </template>

      <v-tooltip bottom v-if="vendor.status === 'active'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark small color="#1F4643" v-bind="attrs" v-on="on" class="custom-button" @click="subscription(vendor, 'cancelled')">
            <v-icon size="10">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Cancel Subscription</span>
      </v-tooltip>

      <!--<v-tooltip bottom v-if="vendor.subscription.status !== 'paused'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark small color="#1F4643" v-bind="attrs" v-on="on" class="custom-button" @click="subscription(vendor, 'paused')">
            <v-icon size="10">mdi-pause</v-icon>
          </v-btn>
        </template>
        <span>Pause Subscription</span>
      </v-tooltip>-->

      <v-tooltip bottom v-if="vendor.status === 'hide' || vendor.status === 'active'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark small color="#1F4643" class="custom-button" v-on="on" @click="hideShowVendor(vendor)">
            <v-icon v-if="vendor.status === 'active'" size="10">mdi-eye-off</v-icon>
            <v-icon v-else size="10">mdi-eye</v-icon>
          </v-btn>
        </template>
        <span v-if="vendor.status === 'active'">Hide {{vendor.type}}</span>
        <span v-else>Show {{vendor.type}}</span>
      </v-tooltip>

    </v-speed-dial>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
export default {
  name: "VendorListingActions",
  props: ['vendor'],
  data: () => ({
    direction: 'left',
    transition: 'slide-x-reverse-transition',
    fab: {},
    form: new Form(),
  }),
  computed: {
    ...mapGetters(['getAuthUser', 'getSubscriptionUpdateStatus'])
  },
  methods: {
    ...mapMutations(['setSubscription']),
    ...mapActions(['updateSubscription', 'loadSubscriptions', 'loadVendors', 'updateVendorStatus']),
    async subscription(vendor={}, type='paused') {
      let payload = {};
      let locationIds = [];
      let hotelIds = [];

      if ( vendor.type === 'hotel' ) { hotelIds.push(vendor.id); }
      if ( vendor.type === 'location' ) { locationIds.push(vendor.id); }
      if ( locationIds.length > 0 ) { payload.locations = locationIds; }
      if ( hotelIds.length > 0 ) { payload.hotel = hotelIds; }
      payload.status = type;
      payload.id = vendor.subscription.id;
      payload = Object.assign({}, payload);
      this.setSubscription(payload);
      await this.updateSubscription();
      this.$emit('subscription-message', type);

      this.loadVendors();
    },
    async hideShowVendor(vendor={}) {
      this.form.id = vendor.id;
      this.form.type = vendor.type;
      if ( vendor.status === 'hide' || vendor.status === 'active' ) { this.form.status = vendor.status === 'hide' ? 'active' : 'hide'; }
      else { this.form.status = 'hide'; }
      let payload = Object.assign({}, this.form);
      await this.updateVendorStatus(payload);
    },
  },
}
</script>
<style scoped>
  .custom-button {
    margin: 5px !important;
    height: 20px !important;
    width: 20px !important;
  }
</style>